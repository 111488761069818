<template>
  <!--   <div>
    <v-file-input v-model="newImage" />
    <v-img :src="preview" />
  </div>
 -->
  <v-row align="center" justify="center">
    <v-col lass="shrink" style="min-width: 270px" cols="12" md="7" sm="7">
      <v-file-input
        :hint="sizeRecommended"
        persistent-hint
        :label="label"
        v-model="newImage"
        prepend-icon="mdi-camera"
      />
    </v-col>
    <v-col lass="shrink" style="min-width: 220px" cols="12" md="5" sm="5">
      <v-img :style="swatchStyle" :src="preview" contain></v-img>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "ImagePickerSelected",
  props: {
    defaultActive: {
      type: Boolean,
      default: false
    },
    sizeRecommended: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    previousImage: {
      String: String,
      default: ""
    }
  },
  data() {
    return {
      newImage: null,
      initImg: null
    };
  },
  watch: {
    defaultActive: function() {
      if (this.defaultActive) {
        this.initImg = null;
        this.newImage = null;
        this.previousImage = null;
      }
    }
  },
  created() {
    this.initImg = this.previousImage;
  },
  computed: {
    swatchStyle() {
      if (this.initImg === null && this.newImage === null) {
        return {
          height: "0px",
          width: "0px"
        };
      } else {
        return {
          height: "auto",
          width: "200px"
        };
      }
    },
    preview() {
      if (!this.newImage) {
        if (this.initImg) {
          this.$emit("setNewImage", this.initImg);
          return this.initImg;
        } else {
          this.$emit("setNewImage", this.newImage);
          return this.newImage;
        }
      } else {
        this.$emit("setNewImage", this.newImage);
        return URL.createObjectURL(this.newImage);
      }
    }
  }
};
</script>
